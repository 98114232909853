<template>
  <div class="account-header">
    <div class="account-header-logo">
    </div>
    <div class="account-header-slot"></div>
    <div class="account-header-title"><span>后台管理系统</span></div>
    <div class="account-header-opt">

    </div>
  </div>
</template>

<script>
import ExchangeSvg from "@/assets/svg/exchange.svg";
import config from "@/config";
const growthUrl = config.growthUrl;

export default {
  name: "Logo",
  props: {
    lang: String,
  },
  components: {},
  data() {
    return {
      ExchangeSvg,
    };
  },
  watch: {
    lang(lang) {
      this.$i18n.locale = lang;
    },
  },
  computed: {
    title() {
      return this.langList[this.lang];
    },
  },
  methods: {
    goGrowth() {
      window.location.href = growthUrl;
    },
    selectLang(name) {
      this.$emit("on-lang-change", name);
    },
    backHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
