
export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '易萃享-机构端登录系统',
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: true,
  useEslint: false,
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    dev: 'http://localhost:8080',
    beta: 'https://auth-beta.leshiguang.com',
    prod: 'https://auth.leshiguang.com'
  },
  serviceUrl: "https://cloud.leshiguang.com/etrition",
  growthUrl: 'https://growth.leshiguang.com',
  copyright:" ",
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: 'home',
}
