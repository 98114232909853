<template>
  <div class="main">
    <logo></logo>
    <div class="account-container">
      <slot></slot>
    </div>
    <slot name="outside"></slot>
    <footer>
      <span class="copy-right">{{ copyright }}</span>
    </footer>
  </div>
</template>

<script>
import Logo from "@/components/logo";
import "./index.less";
import config from "@/config";
const copyright = config.copyright;
export default {
  name: "account-layout",
  components: {
    Logo,
  },
  data() {
    return {
      copyright,
    };
  },
  methods: {
  },
};
</script>