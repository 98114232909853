import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register')
  },
  {
    path: '/retrieve-pass',
    name: 'retrieve-pass',
    component: () => import('@/views/retrieve-pass')
  },
  {
    path: '/multi-company',
    name: 'multi-company',
    component: () => import('@/views/multi-company')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
